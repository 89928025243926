import { Input, InputProps } from "@chakra-ui/react";

export type CustomInputProps = Omit<InputProps, "value" | "onChange"> & {
  value: string;
  onChange: (value: string) => void;
};

export const CustomInput = ({ onChange, value, ...props }: CustomInputProps) => {
  const handleChange = (event: { target: { value: string } }) => onChange(event.target.value);
  return <Input onChange={handleChange} value={value} {...props} />;
};

export default CustomInput;
