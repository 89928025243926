import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FlexProps,
  HStack,
  Heading,
  Icon,
  IconButton,
  Spacer,
  chakra,
  useColorMode
} from "@chakra-ui/react";
import {
  setAxiosClientLanguage,
  useBonusDescription,
  useBuildingsDescription,
  useCommunicationShort,
  useDefenceDescription,
  useFactionDescription,
  useRaceDescription,
  useShipComponentsDescription,
  useTechnologiesDescription
} from "@new-wars/core";
import { signOut, useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import ReactCountryFlag from "react-country-flag";
import { RiMessageLine } from "react-icons/ri";
import SideMenu from "../SideMenu/SideMenu";
import LinkNext from "../UI/LinkNext/LinkNext";
import useDeviceTypes from "../hooks/useDeviceTypes";
import { TopBarModel } from "./TopBarModel";

export const ActiveLinkNext = ({ route, name }: { route: string; name: string }) => {
  const router = useRouter();
  const isActive = useMemo(() => router.pathname === route, [router.pathname, route]);
  return (
    <LinkNext route={route}>
      <Button variant="link" colorScheme={"gray"} mr="4" color={isActive ? "orange.600" : undefined}>
        {name}
      </Button>
    </LinkNext>
  );
};

interface TopBarProps extends FlexProps {
  model: TopBarModel;
  setNewMessageModalOpen: (_val: boolean) => void;
}

export const LanguageBar = () => {
  const router = useRouter();
  const { colorMode, toggleColorMode } = useColorMode();
  const { mutateBonusDesc } = useBonusDescription();
  const { mutateFactionDesc } = useFactionDescription();
  const { mutateRaceDesc } = useRaceDescription();
  const { mutateShipComponentsDesc } = useShipComponentsDescription();
  const { mutateDefenceDesc } = useDefenceDescription();
  const { mutateBuildingsDesc } = useBuildingsDescription();
  const { mutateTechnologiesDesc } = useTechnologiesDescription();

  const changeLanguage = useCallback((lang: string) => {
    const { pathname, asPath, query } = router;
    setAxiosClientLanguage(lang);
    mutateBonusDesc();
    mutateFactionDesc();
    mutateRaceDesc();
    mutateShipComponentsDesc();
    mutateDefenceDesc();
    mutateBuildingsDesc();
    mutateTechnologiesDesc();
    router.push({ pathname, query }, asPath, { locale: lang });
  }, []);

  return (
    <>
      <IconButton
        aria-label="polski"
        icon={
          <ReactCountryFlag
            style={{
              fontSize: "20px"
            }}
            countryCode={"pl"}
            svg
          />
        }
        onClick={() => changeLanguage("pl")}
      />
      <IconButton
        aria-label="dark-mode"
        icon={
          <ReactCountryFlag
            style={{
              fontSize: "20px"
            }}
            countryCode={"GB"}
            svg
          />
        }
        onClick={() => changeLanguage("en")}
        mr={4}
      />
      <IconButton
        aria-label="dark-mode"
        icon={
          <ReactCountryFlag
            style={{
              fontSize: "20px"
            }}
            countryCode={"DE"}
            svg
          />
        }
        onClick={() => changeLanguage("de")}
        mr={4}
      />
      <IconButton
        aria-label="dark-mode"
        icon={colorMode === "dark" ? <SunIcon /> : <MoonIcon />}
        onClick={toggleColorMode}
        mr={4}
      />
    </>
  );
};

export const TopBar = ({ model, setNewMessageModalOpen, ...rest }: TopBarProps) => {
  const router = useRouter();
  const { status } = useSession();
  const { t } = useTranslation(["common", "buildings"]);

  const isAuthenticated = status === "authenticated";
  const comm = useCommunicationShort(isAuthenticated);
  const [isMobile] = useDeviceTypes();
  const showInDesktop = !isAuthenticated && !isMobile;
  return (
    <Flex
      flexDirection={{ md: "row" }}
      justifyContent={{ base: "center", md: "flex-start" }}
      shadow={4}
      py={4}
      {...rest}
    >
      <Box mr={1}>
        <Heading
          textAlign={{ base: "center" }}
          pl={{ base: 0, sm: 0, md: 5, lg: 10, xl: 0 }}
          size="2xl"
          onClick={() => router.push("/")}
        >
          {model.companyName}
        </Heading>
      </Box>
      <Spacer />

      <HStack
        display={"flex"}
        mr={{ base: 5, sm: 20, md: 50, lg: 100, xl: 50, "2xl": 5 }}
        ml={1}
        flexShrink={1}
        flexWrap={"wrap"}
      >
        <SideMenu linkItems={model.linkItems} companyName={model.companyName} isAuthenticated={isAuthenticated} />
        {!isMobile ? (
          isAuthenticated ? (
            model.linkItems.map(link =>
              link.showInTopBar ? <ActiveLinkNext key={link.route} route={link.route} name={t(link.title)} /> : null
            )
          ) : (
            <>
              <ActiveLinkNext route={"/ranking/summary"} name={t("ranking")} />
              <ActiveLinkNext route={"/users"} name={t("users")} />
            </>
          )
        ) : null}
        {isAuthenticated ? (
          <IconButton aria-label="New message" icon={<RiMessageLine />} onClick={() => setNewMessageModalOpen(true)} />
        ) : null}
        {isAuthenticated ? (
          <LinkNext route={"/messages/summary"}>
            <chakra.span pos="relative" display="inline-block" ml={8}>
              <Icon
                boxSize={6}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <RiMessageLine />
              </Icon>
              {comm?.communicationShort?.unreadCount && comm?.communicationShort?.unreadCount > 0 ? (
                <chakra.span
                  pos="absolute"
                  top="-1px"
                  right="-1px"
                  px={2}
                  py={1}
                  fontSize="xs"
                  fontWeight="bold"
                  lineHeight="none"
                  color="red.100"
                  transform="translate(50%,-50%)"
                  bg="red.600"
                  rounded="full"
                >
                  {comm?.communicationShort?.unreadCount ?? 0}
                </chakra.span>
              ) : null}
            </chakra.span>
          </LinkNext>
        ) : null}
      </HStack>
      <Spacer />
      <HStack mr={{ base: 5, sm: 20, md: 50, lg: 100, xl: 100, "2xl": 125 }} ml={4}>
        {!isMobile ? <LanguageBar /> : null}
        {showInDesktop ? (
          <LinkNext route={isAuthenticated ? "/dashboard" : "/register"}>
            <Button variant="link" colorScheme={"gray"} mr="4">
              {isAuthenticated ? t("dashboard") : model.companyButton.toUpperCase()}
            </Button>
          </LinkNext>
        ) : null}
        {!isMobile ? (
          <LinkNext route={isAuthenticated ? "/" : "/login"}>
            <Button
              colorScheme={"gray"}
              mr="4"
              variant="outline"
              onClick={e => {
                if (isAuthenticated) {
                  e.preventDefault();
                  signOut({ redirect: true, callbackUrl: "/" });
                }
              }}
            >
              {isAuthenticated ? t("logout") : t("login")}
            </Button>
          </LinkNext>
        ) : null}
      </HStack>
    </Flex>
  );
};

export default TopBar;
