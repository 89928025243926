import { Text } from "@chakra-ui/react";

export const ResourceText = ({
  title,
  resource,
  isEnough
}: {
  title: string;
  resource?: number;
  isEnough?: boolean;
}) => {
  return resource && resource > 0 ? <Text textAlign={"center"} color={isEnough ? "green" : "red"}>{`${title}: ${resource}`}</Text> : null;
};

export default ResourceText;
