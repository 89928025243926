import { Link, LinkProps } from "@chakra-ui/react";
import NextLink from "next/link";

export interface LinkNextProps extends LinkProps {
  route: string;
}

export const LinkNext = ({ children, route, ...rest }: LinkNextProps) => {
  return (
    <Link as={NextLink} href={route} {...rest}>
      {children}
    </Link>
  );
};

export default LinkNext;
