import { Button, ButtonProps } from "@chakra-ui/react";

export interface InputWithIconProps extends ButtonProps {
  children: React.ReactNode;
}

export const BrandButton = ({ children, ...props }: InputWithIconProps) => {
  return (
    <Button colorScheme={"brand"} {...props}>
      {children}
    </Button>
  );
};

export default BrandButton;
