import { Flex, FlexProps, Icon } from "@chakra-ui/react";
import { ReactNode } from "react";
import { IconTypes } from "../../types";
import LinkNext from "../LinkNext/LinkNext";

export interface NavLinkItemProps extends FlexProps {
  icon: IconTypes;
  route: string;
  children: ReactNode;
}

export const NavLinkItem = ({ icon, children, route, ...rest }: NavLinkItemProps) => {
  return (
    <LinkNext route={route} style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="g
          roup"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white"
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white"
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </LinkNext>
  );
};

export default NavLinkItem;
