import { isValueAnEmptyObject, Model } from "@new-wars/core";
import { ConstructorOf } from "@new-wars/core/src/mvvm/core";
import { IModelBuilder } from "@new-wars/core/src/mvvm/ModelBuilder";
import { useRouter } from "next/router";
import { FC } from "react";
import { isBrowser } from "../utils/helpers";

export function withModel<T extends Model, U extends IModelBuilder<T>>(
  Component: FC<{ model: T }>,
  ModelBuilder: ConstructorOf<U>
) {
  return ({ apiData }: { apiData: { title: string; list: string[] } }) => {
    const router = useRouter();
    const builder = new ModelBuilder();
    const model = builder.build(apiData);

    if (!apiData || isValueAnEmptyObject(apiData)) {
      // To prevent calling router.push on server(SSR)
      // TODO Przenieść logikę zabezpieczonych stron tutaj
      isBrowser && router.push("/error");
      return <div />;
    }
    return <Component model={model} />;
  };
}
