import { Box, Button, Card, CardBody, CardFooter, Progress, Text, useColorModeValue } from "@chakra-ui/react";
import { ProgressType } from "@new-wars/core";
import { intervalToDuration } from "date-fns";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "next-i18next";
import { useEffect, useMemo, useState } from "react";
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

type ProgressProps = {
  buildStart: Date;
  buildEnd: Date;
  nextLevel: number;
  title: string;
  progressType?: ProgressType;
  onFinishBuilding: () => void;
  cancel: () => void;
  small?: boolean;
  loading: boolean;
};

export const ProgressItem = ({
  buildStart,
  buildEnd,
  nextLevel,
  title,
  onFinishBuilding,
  cancel,
  progressType,
  small = false,
  loading = false
}: ProgressProps) => {
  const [isDone, setIsDone] = useState(false);
  const startBuild = new Date(buildStart);
  const endDate = new Date(buildEnd);
  const countDown = useMemo(() => endDate.getTime() - startBuild.getTime(), []);
  const [timestampLeft, setTimestampLeft] = useState(countDown);
  const { t } = useTranslation(["common"]);
  const intervalTime = 1000;

  useEffect(() => {
    const interval = setInterval(() => {
      const c = endDate.getTime() - Date.now() - 1000; // 1000 ms before time of end to not get error of intervalToDuration
      if (c < 0) {
        setIsDone(true);
        onFinishBuilding();
        clearInterval(interval);
      } else {
        setTimestampLeft(c);
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, []);
  const intervalTimeTDuration = !isDone
    ? { hours: 0, minutes: 0, seconds: 0, ...intervalToDuration({ start: new Date(), end: endDate }) }
    : { hours: 0, minutes: 0, seconds: 0 };

  const textTime = dayjs.duration(intervalTimeTDuration).format("HH:mm:ss");
  const bg = useColorModeValue("gray.200", "gray.900");
  return !isDone ? (
    small ? (
      <Card maxW={180} variant={"filled"} align="center" colorScheme={"cyan"} mb={2} bg={bg} boxShadow="lg">
        <CardBody>
          <Text>{`${progressType === ProgressType.Upgrade ? `${t("upgrade")}: ` : ""}${title}`}</Text>
          <Text>{`${
            progressType === ProgressType.Technology ? `${t("endOfResearch")}: ` : `${t("endOfBuilding")}: `
          }${textTime}`}</Text>
          <Progress colorScheme="green" size="sm" value={timestampLeft} min={countDown} max={0} />
        </CardBody>
        <CardFooter pt={1}>
          <Button isLoading={loading} onClick={cancel} colorScheme={"red"}>
            {t("cancel")}
          </Button>
        </CardFooter>
      </Card>
    ) : (
      <Box
        display={"flex"}
        alignItems={"center"}
        maxWidth={small ? 60 : undefined}
        flexDirection={small ? "column" : "row"}
      >
        <Box mr={2} flex={1} pr={1} my={4}>
          <Text>{`${progressType === ProgressType.Upgrade ? `${t("upgrade")}: ` : ""}${title}`}</Text>
          <Text>{`${
            progressType === ProgressType.Technology ? `${t("endOfResearch")}: ` : `${t("endOfBuilding")}: `
          }${textTime}`}</Text>
          <Progress colorScheme="green" size="sm" value={timestampLeft} min={countDown} max={0} />
          <Text>{`${t("nextLevel")}: ${nextLevel}`}</Text>
        </Box>
        <Button isLoading={loading} onClick={cancel} colorScheme={"red"}>
          {t("cancel")}
        </Button>
      </Box>
    )
  ) : (
    <></>
  );
};

export default ProgressItem;
