import { RadioGroup, RadioGroupProps } from "@chakra-ui/react";

export type CustomRadioGroupProps = Omit<RadioGroupProps, "value" | "onChange"> & {
  value: number;
  onChange: (value: number) => void;
};

export const CustomRadioGroup = ({ onChange, value, children, ...props }: CustomRadioGroupProps) => {
  const handleChange = (nextValue: string) => onChange(Number.parseInt(nextValue));
  return (
    <RadioGroup onChange={handleChange} value={value.toString()} {...props}>
      {children}
    </RadioGroup>
  );
};

export default CustomRadioGroup;
