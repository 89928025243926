import { Box, BoxProps, CloseButton, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { signOut } from "next-auth/react";
import { useTranslation } from "next-i18next";
import NavLinkItem from "../../../UI/NavLinkItem/NavLinkItem";
import { NavItem } from "../../../types";

export interface SidebarProps extends BoxProps {
  onClose: () => void;
  companyName: string;
  linkItems: NavItem[];
  isAuthenticated: boolean;
}

export const SidebarContent = ({ onClose, companyName, linkItems, isAuthenticated, ...rest }: SidebarProps) => {
  const { t } = useTranslation(["common"]);
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="bold">
          {companyName}
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {linkItems.map(link =>
        link.authentication === isAuthenticated ? (
          <NavLinkItem
            key={link.title}
            icon={link.icon}
            href={link.route}
            onClick={e => {
              if (isAuthenticated && link.route === "/logout") {
                e.preventDefault();
                signOut({ redirect: true, callbackUrl: "/" });
              }
            }}
          >
            {t(link.title)}
          </NavLinkItem>
        ) : null
      )}
    </Box>
  );
};

export default SidebarContent;
