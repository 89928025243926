import { useEffect, useState } from "react";
import { isBrowser } from "../utils/helpers";

export default function useWindowDimensions() {
  function getWindowDimensions() {
    const width = isBrowser ? window.innerWidth : null;
    const height = isBrowser ? window.innerHeight : null;
    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (isBrowser) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [isBrowser]);

  return windowDimensions;
}
