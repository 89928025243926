import { Drawer, DrawerContent, DrawerOverlay, IconButton, useDisclosure } from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import useDeviceTypes from "../hooks/useDeviceTypes";
import { NavItem } from "../types";
import SidebarContent from "./components/SidebarContent/SidebarContent";

export type SideMenuProps = {
  companyName: string;
  linkItems: NavItem[];
  isAuthenticated: boolean;
};

export const SideMenu = ({ linkItems, companyName, isAuthenticated }: SideMenuProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useDeviceTypes();
  return isMobile ? (
    <>
      <IconButton
        display={"flex"}
        onClick={onOpen}
        ml={{ base: 10 }}
        color={"white"}
        bg="brand.500"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size={"sm"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            companyName={companyName}
            linkItems={linkItems}
            isAuthenticated={isAuthenticated}
          />
        </DrawerContent>
      </Drawer>
    </>
  ) : null;
};

export default SideMenu;
