export const isBrowser = typeof window !== "undefined";

/**
 * Based on provided Date object returns string with HH:mm format.
 * @param date source date
 * @returns string with HH:mm format of the date
 */
export function getPrettyHour(date: Date) {
  return date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit"
  });
}

export function convertTimeToDate(time: string) {
  const date = new Date(new Date().setHours(0, 0, 0));
  let [hour, minutes] = time.split(":", 2).map(hour => parseInt(hour));
  date.setHours(hour, minutes);
  return date;
}

export function convertToTimeString(time: string) {
  const date = convertTimeToDate(time);
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}
